import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { PricingCard, ListGroup, Badge } from './style';
import Heading from '../../../../../components/heading/heading';
import { List } from '../../../../../components/pricing/pricing';
import { Button } from '../../../../../components/buttons/buttons';
import { DataService } from '../../../../../config/dataService/dataService';
import { setItem } from '../../../../../utility/localStorageControl';

const subscribeTrial = () => DataService.post('/stripe/checkout');

const pricingData = [
  {
    min_power_units: 1,
    max_power_units: 1,
    price: 29.99,
    type: 'Owner Operator',
  },
  {
    min_power_units: 2,
    max_power_units: 10,
    price: 39.99,
    type: 'Fleet Operator',
  },
  {
    min_power_units: 11,
    max_power_units: 20,
    price: 49.99,
    type: 'Fleet Operator',
  },
  {
    min_power_units: 21,
    max_power_units: 50,
    price: 59.99,
    type: 'Fleet Operator',
  },
  {
    min_power_units: 50,
    max_power_units: 999999999,
    price: 99.99,
    type: 'Fleet Operator',
  },
];

const getPricingData = (powerUnits) => {
  let pricing = pricingData[0];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pricingData.length; i++) {
    if (powerUnits >= pricingData[i].min_power_units && powerUnits <= pricingData[i].max_power_units) {
      pricing = pricingData[i];
      break;
    }
  }
  return pricing;
};

function Pricing({ data }) {
  const [loading, setLoading] = React.useState(false);
  const powerUnits = data.power_units_count;
  const pricing = getPricingData(powerUnits);
  return (
    <Row justify="center">
      <Col xs={24}>
        <PricingCard style={{ marginTop: 30 }}>
          <Badge className="pricing-badge" type="primary">
            Basic Plan
          </Badge>
          <Heading className="price-amount" as="h3">
            <sup className="currency">$</sup>
            {pricing.price} <sub className="pricing-validity">Per month</sub>
          </Heading>
          <span className="package-user-type">For {pricing.type}</span>
          <ListGroup>
            <List text="100GB File Space" />
            <List text="300 Projects" />
            <List text="Limited Boards" />
            <List text="Basic Project Management" />
            <List text="Custom Post Types" />
          </ListGroup>
          <Button
            onClick={async () => {
              setLoading(true);
              try {
                const response = await subscribeTrial();
                console.log('response: ', response);
                setItem('access_token', response.data.accessToken);
                setItem('refresh_token', response.data.refreshToken);
                setItem('loggedIn', true);
              } catch (err) {
                console.log('err: ', err);
                message.error('Something went wrong. Please contact support!');
              } finally {
                setLoading(false);
              }
            }}
            loading={loading}
            size="default"
            type="primary"
          >
            Start 60-days free trial
          </Button>
          <Row
            style={{
              width: '100%',
              marginTop: '1rem',
              textAlign: 'center',
            }}
          >
            <Col md={12} xs={24}>
              <p className="package-validity">
                <CheckOutlined /> Totally free for 60 days
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p className="package-validity">
                <CheckOutlined /> No credit card required
              </p>
            </Col>
          </Row>
        </PricingCard>
      </Col>
    </Row>
  );
}

Pricing.propTypes = {
  data: PropTypes.object,
};

export default Pricing;
