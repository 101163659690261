import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const ComplaintList = lazy(() => import('../../container/pages/collections/Complaints'));
const CollectionPartnerList = lazy(() => import('../../container/pages/collections/CollectionPartnerList'));
const ComplaintDetails = lazy(() => import('../../container/pages/complaint/ComplaintDetails'));

function CollectionsRoutes() {
  return (
    <Routes>
      <Route path="/complaints" element={<ComplaintList />} />
      <Route path="/partners" element={<CollectionPartnerList />} />
      <Route path="/:id" element={<ComplaintDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default CollectionsRoutes;
