/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { Button, Col, Layout, Row, Spin } from 'antd';
import propTypes from 'prop-types';
import { LoadingOutlined, LockFilled } from '@ant-design/icons';
import { Component } from 'react';
import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
import { connect } from 'react-redux';
import { Link, NavLink, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import MenueItems from './SideMenuItems';
import { FooterStyle, LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from './Style';
import TopMenu from './TopMenu';
import Search from '../components/utilities/auth-info/Search';
import AuthInfo from '../components/utilities/auth-info/info';
import { Modal } from '../components/modals/antd-modals';
import Pricing from '../container/profile/authentication/overview/components/Pricing';
import actions from '../redux/me/actions';

const { theme } = require('../config/theme/themeVariables');

const { Header, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentDidUpdate() {
      const { currentUser } = this.props;
      if (
        !['/', '/profile', '/billing'].includes(window.location.pathname) &&
        currentUser.subscriptionActive === false
      ) {
        // eslint-disable-next-line no-restricted-globals
        <Navigate to="/" replace />;
      }
      // )}
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide } = this.state;
      const { layoutMode, rtl, topMenu, meLoading, currentUser, paymentModalVisible, setPaymentModalVisible } =
        this.props;

      const left = !rtl ? 'left' : 'right';
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      console.log('window.location.pathname: ', window.location.pathname);

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
        });
      };

      const SideBarStyle = {
        margin: '63px 0 0 0',
        padding: `${!rtl ? '20px 20px 55px 0' : '20px 0 55px 20px'}`,
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 988,
      };

      const renderView = ({ style }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const renderThumbHorizontal = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      return (
        <LayoutContainer>
          <Spin spinning={meLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            <Layout className="layout">
              <Header
                style={{
                  position: 'fixed',
                  width: '100%',
                  top: 0,
                  [!rtl ? 'left' : 'right']: 0,
                }}
              >
                <div className="ninjadash-header-content d-flex">
                  <div className="ninjadash-header-content__left">
                    <div className="navbar-brand align-cener-v">
                      <Link
                        className={topMenu && window.innerWidth > 991 ? 'ninjadash-logo top-menu' : 'ninjadash-logo'}
                        to="/"
                      >
                        <img
                          src={
                            layoutMode === 'lightMode'
                              ? require(`../static/img/broker_logo.png`)
                              : require(`../static/img/broker_logo.png`)
                          }
                          alt=""
                        />
                      </Link>
                      {!topMenu || window.innerWidth <= 991 ? (
                        <Button type="link" onClick={toggleCollapsed}>
                          <img
                            src={require(`../static/img/icon/${collapsed ? 'left-bar.svg' : 'left-bar.svg'}`)}
                            alt="menu"
                          />
                        </Button>
                      ) : null}
                    </div>
                  </div>
                  <div className="ninjadash-header-content__right d-flex">
                    <div className="ninjadash-navbar-menu d-flex align-center-v">
                      {topMenu && window.innerWidth > 991 ? <TopMenu /> : ''}
                    </div>
                    <div className="ninjadash-nav-actions">
                      {topMenu && window.innerWidth > 991 ? (
                        <TopMenuSearch>
                          <div className="top-right-wrap d-flex">
                            <AuthInfo />
                          </div>
                        </TopMenuSearch>
                      ) : (
                        <AuthInfo />
                      )}
                    </div>
                  </div>
                  <div className="ninjadash-header-content__mobile">
                    <div className="ninjadash-mobile-action">
                      <div className="btn-search" to="#">
                        <Search />
                      </div>

                      <Link className="btn-auth" onClick={onShowHide} to="#">
                        <UilEllipsisV />
                      </Link>
                    </div>
                  </div>
                </div>
              </Header>
              <div className="ninjadash-header-more">
                <Row>
                  <Col md={0} sm={24} xs={24}>
                    <div className="ninjadash-header-more-inner">
                      <SmallScreenAuthInfo hide={hide}>
                        <AuthInfo rtl={rtl} />
                      </SmallScreenAuthInfo>
                    </div>
                  </Col>
                </Row>
              </div>
              <Layout>
                {!topMenu || window.innerWidth <= 991 ? (
                  <ThemeProvider theme={theme}>
                    <Sider
                      width={280}
                      style={SideBarStyle}
                      collapsed={collapsed}
                      theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                    >
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={renderThumbHorizontal}
                        renderThumbVertical={renderThumbVertical}
                        renderView={renderView}
                        renderTrackVertical={(props) => <div {...props} className="ninjadash-track-vertical" />}
                      >
                        {currentUser.subscriptionActive === false && (
                          <div
                            style={{
                              position: 'absolute',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              width: '100%',
                              background: 'rgba(255, 255, 255, 0.5)',
                              zIndex: 999,
                            }}
                            onClick={() => {
                              setPaymentModalVisible(true);
                            }}
                          >
                            <LockFilled
                              style={{
                                fontSize: '50px',
                                color: '#999',
                                width: '100%',
                              }}
                            />
                          </div>
                        )}
                        <MenueItems topMenu={topMenu} toggleCollapsed={toggleCollapsedMobile} />
                      </Scrollbars>
                    </Sider>
                  </ThemeProvider>
                ) : null}
                <Layout className="atbd-main-layout">
                  <Content>
                    <WrappedComponent {...this.props} />
                    <FooterStyle className="admin-footer">
                      <Row>
                        <Col md={12} xs={24}>
                          <span className="admin-footer__copyright">
                            © 2023<Link to="#">Broker411</Link>
                          </span>
                        </Col>
                        <Col md={12} xs={24}>
                          <div className="admin-footer__links">
                            <NavLink to="#">About</NavLink>
                            <NavLink to="#">Team</NavLink>
                            <NavLink to="#">Contact</NavLink>
                          </div>
                        </Col>
                      </Row>
                    </FooterStyle>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          </Spin>
          {window.innerWidth <= 991 ? (
            <span className={collapsed ? 'ninjadash-shade' : 'ninjadash-shade show'} onClick={toggleCollapsed} />
          ) : (
            ''
          )}
          {paymentModalVisible && !meLoading && (
            <Modal onCancel={() => setPaymentModalVisible(false)} visible footer={null}>
              <Pricing data={currentUser} />
            </Modal>
          )}
        </LayoutContainer>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      currentUser: state.currentUser.data,
      meLoading: state.currentUser.loading,
      paymentModalVisible: state.currentUser.paymentModalVisible,
    };
  };

  function mapDispatchToProps(dispatch) {
    return {
      setPaymentModalVisible: (visible) => {
        dispatch(actions.setPaymentModalVisible(visible));
      },
    };
  }

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
  };

  return connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
};
export default ThemeLayout;
