import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Maintenance = lazy(() => import('../../container/pages/Maintenance'));
const Profile = lazy(() => import('../../container/profile/userProfile'));

function ProfileRoute() {
  return (
    <Routes>
      <Route path="/*" element={<Profile />} />
      <Route path="*" element={<NotFound />} />
      <Route path="maintenance" element={<Maintenance />} />
    </Routes>
  );
}

export default ProfileRoute;
