const actions = {
  CREATE_EMPLOYEE_BEGIN: 'CREATE_EMPLOYEE_BEGIN',
  CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
  CREATE_EMPLOYEE_ERROR: 'CREATE_EMPLOYEE_ERROR',
  GET_EMPLOYEE_LIST_BEGIN: 'GET_EMPLOYEE_LIST_BEGIN',
  GET_EMPLOYEE_LIST_SUCCESS: 'GET_EMPLOYEE_LIST_SUCCESS',
  GET_EMPLOYEE_LIST_ERROR: 'GET_EMPLOYEE_LIST_ERROR',

  createEmployee: () => {
    return {
      type: actions.CREATE_EMPLOYEE_BEGIN,
    };
  },

  createEmployeeSuccess: (data) => {
    return {
      type: actions.CREATE_EMPLOYEE_SUCCESS,
      data,
    };
  },

  createEmployeeError: (err) => {
    return {
      type: actions.CREATE_EMPLOYEE_ERROR,
      err,
    };
  },

  getListBegin: () => {
    return {
      type: actions.GET_EMPLOYEE_LIST_BEGIN,
    };
  },
  getListSuccess: (data) => {
    return {
      type: actions.GET_EMPLOYEE_LIST_SUCCESS,
      data,
    };
  },

  getListError: (err) => {
    return {
      type: actions.GET_EMPLOYEE_LIST_ERROR,
      err,
    };
  },
};

export default actions;
