import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
import Brokers from './brokers';
import Profile from './profile';
import Complaint from './complaint';
import Carrier from './carrier';
import Collections from './collections';
import DispatchGuard from './dispatchguard';
import Contactus from './contactus';
import DieselPrice from './dieselPrice';
import withAdminLayout from '../../layout/withAdminLayout';

const Tickets = lazy(() => import('../../container/supportTicket/Index'));
const AddTicket = lazy(() => import('../../container/supportTicket/AddSupport'));
const TicketDetails = lazy(() => import('../../container/supportTicket/SupportTicketDetails'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Dashboard />} />
        <Route path="brokers/*" element={<Brokers />} />
        <Route path="complaint/*" element={<Complaint />} />
        <Route path="collections/*" element={<Collections />} />
        <Route path="dispatchguard/*" element={<DispatchGuard />} />
        <Route path="contactus/*" element={<Contactus />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="carrier/*" element={<Carrier />} />
        <Route path="diesel/*" element={<DieselPrice />} />
        <Route path="support/tickets/*" element={<Tickets />} />
        <Route path="support/tickets/add" element={<AddTicket />} />
        <Route path="support/ticketDetails/:id" element={<TicketDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
