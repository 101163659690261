import actions from './actions';

const {
  CREATE_EMPLOYEE_BEGIN,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_ERROR,
  GET_EMPLOYEE_LIST_BEGIN,
  GET_EMPLOYEE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_ERROR,
} = actions;

const initState = {
  employeeCreateLoading: false,
  employeeCreateError: null,
  employeeList: [],
  employeeListTotal: 0,
  employeeListLoading: false,
  employeeListError: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const inviteEmployeeReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_EMPLOYEE_BEGIN:
      return {
        ...state,
        employeeCreateLoading: true,
      };
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeCreateLoading: false,
      };
    case CREATE_EMPLOYEE_ERROR:
      return {
        ...state,
        employeeCreateError: err,
        employeeCreateLoading: false,
      };
    case GET_EMPLOYEE_LIST_BEGIN:
      return {
        ...state,
        employeeListLoading: true,
      };
    case GET_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        employeeList: data,
        employeeListTotal: data.total,
        employeeListLoading: false,
      };
    case GET_EMPLOYEE_LIST_ERROR:
      return {
        ...state,
        employeeCreateError: err,
        employeeListLoading: false,
      };
    default:
      return state;
  }
};
export default inviteEmployeeReducer;
