import actions from './actions';

const {
  GET_LIST_BEGIN,
  GET_LIST_SUCCESS,
  GET_LIST_ERR,
  RESET_LIST_STATE,

  GET_DETAILS_BEGIN,
  GET_DETAILS_SUCCESS,
  GET_DETAILS_ERR,

  UPDATE_DETAILS_BEGIN,
  UPDATE_DETAILS_SUCCESS,
  UPDATE_DETAILS_ERR,

  RESET_DETAILS_STATE,
} = actions;

const initState = {
  list: [],
  listTotal: 0,
  details: {},
  listLoading: false,
  detailsLoading: false,
  listError: null,
  detailsError: null,
  updateLoading: false,
  updateError: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const brokerReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_LIST_BEGIN:
      return {
        ...state,
        listLoading: true,
      };
    case GET_LIST_SUCCESS:
      return {
        ...state,
        list: data.brokers,
        listTotal: data.total,
        listLoading: false,
      };
    case GET_LIST_ERR:
      return {
        ...state,
        listError: err,
        listLoading: false,
      };
    case RESET_LIST_STATE:
      return {
        ...state,
        list: [],
        listLoading: false,
        listError: null,
      };

    case GET_DETAILS_BEGIN:
      return {
        ...state,
        detailsLoading: true,
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: data,
        detailsLoading: false,
      };
    case GET_DETAILS_ERR:
      return {
        ...state,
        detailsError: err,
        detailsLoading: false,
      };

    case UPDATE_DETAILS_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };
    case UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      };
    case UPDATE_DETAILS_ERR:
      return {
        ...state,
        updateError: err,
        updateLoading: false,
      };

    case RESET_DETAILS_STATE:
      return {
        ...state,
        details: {},
        detailsLoading: false,
        detailsError: null,
      };
    default:
      return state;
  }
};
export default brokerReducer;
