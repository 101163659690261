import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const ContactList = lazy(() => import('../../container/pages/contactUs/ContactList'));

function DispatchGuardRoute() {
  return (
    <Routes>
      <Route path="" element={<ContactList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DispatchGuardRoute;
