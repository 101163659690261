import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const BrokersList = lazy(() => import('../../container/brokers/List'));
const NotFound = lazy(() => import('../../container/pages/404'));
// eslint-disable-next-line import/no-unresolved
const Profile = lazy(() => import('../../container/profile/myProfile'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="" element={<BrokersList />} />
      <Route path="/:usdot/*" element={<Profile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
