import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const ComplaintList = lazy(() => import('../../container/pages/complaint/ComplaintList'));
const ComplaintDetails = lazy(() => import('../../container/pages/complaint/ComplaintDetails'));

function DispatchGuardRoute() {
  return (
    <Routes>
      <Route path="" element={<ComplaintList />} />
      <Route path="/:id" element={<ComplaintDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DispatchGuardRoute;
