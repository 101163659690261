import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const CarrierSubscriptionList = lazy(() => import('../../container/pages/carrier/CarrierSubscriptionList'));
const CarrierStatusList = lazy(() => import('../../container/pages/carrier/CarrierStatusList'));
const CarrierDetails = lazy(() => import('../../container/pages/carrier/CarrierDetails'));

function CollectionsRoutes() {
  return (
    <Routes>
      <Route path="/subscription" element={<CarrierSubscriptionList />} />
      <Route path="/status" element={<CarrierStatusList />} />
      <Route path="/:id/" element={<CarrierDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default CollectionsRoutes;
