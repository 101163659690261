const actions = {
  GET_LIST_BEGIN: 'GET_LIST_BEGIN',
  GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
  GET_LIST_ERR: 'GET_LIST_ERR',
  RESET_LIST_STATE: 'RESET_LIST_STATE',

  GET_DETAILS_BEGIN: 'GET_DETAILS_BEGIN',
  GET_DETAILS_SUCCESS: 'GET_DETAILS_SUCCESS',
  GET_DETAILS_ERR: 'GET_DETAILS_ERR',

  UPDATE_DETAILS_BEGIN: 'UPDATE_DETAILS_BEGIN',
  UPDATE_DETAILS_SUCCESS: 'UPDATE_DETAILS_SUCCESS',
  UPDATE_DETAILS_ERROR: 'UPDATE_DETAILS_ERROR',

  RESET_DETAILS_STATE: 'RESET_DETAILS_STATE',

  getBrokersListBegin: () => {
    return {
      type: actions.GET_LIST_BEGIN,
    };
  },
  getBrokersListSuccess: (data) => {
    return {
      type: actions.GET_LIST_SUCCESS,
      data,
    };
  },
  getBrokersListErr: (err) => {
    return {
      type: actions.GET_LIST_ERR,
      err,
    };
  },
  resetBrokerListState: () => {
    return {
      type: actions.RESET_LIST_STATE,
    };
  },

  getBrokerDetailsBegin: () => {
    return {
      type: actions.GET_DETAILS_BEGIN,
    };
  },
  getBrokerDetailsSuccess: (data) => {
    return {
      type: actions.GET_DETAILS_SUCCESS,
      data,
    };
  },
  getBrokerDetailsErr: (err) => {
    return {
      type: actions.GET_DETAILS_ERR,
      err,
    };
  },

  updateBrokerDetailsBegin: () => {
    return {
      type: actions.UPDATE_DETAILS_BEGIN,
    };
  },
  updateBrokerDetailsSuccess: (data) => {
    return {
      type: actions.UPDATE_DETAILS_SUCCESS,
      data,
    };
  },
  updateBrokerDetailsErr: (err) => {
    return {
      type: actions.UPDATE_DETAILS_ERR,
      err,
    };
  },

  resetBrokerDetailsState: () => {
    return {
      type: actions.RESET_DETAILS_STATE,
    };
  },
};

export default actions;
