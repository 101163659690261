import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const DieselPricePage = lazy(() => import('../../container/prices/DieselPricePage'));

function DieselPrice() {
  return (
    <Routes>
      <Route path="" element={<DieselPricePage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DieselPrice;
