import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const ReportList = lazy(() => import('../../container/pages/complaint/ReportList'));
const MyComplaints = lazy(() => import('../../container/pages/complaint/MyComplaints'));

function DispatchGuardRoute() {
  return (
    <Routes>
      <Route path="" element={<ReportList />} />
      <Route path="/:id" element={<MyComplaints />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DispatchGuardRoute;
